.imgSectionContainer {
  background: var(--body-bg-color);
  padding: 80px 0 80px 0;
}
.imgSectionContainer .feature {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.imgSectionContainer .feature h5 {
  font-weight: 600;
  color: var(--primary-black);
  padding-bottom: 5px;
}
.imgSectionContainer .featureImg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.imgSectionContainer .featureImg .imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 700px;
  width: 700px;
}
.imgSectionContainer .featureImg .imgContainer img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .imgSectionContainer .featureImg .imgContainer {
    height: 500px;
    width: 500px;
  }
}
@media only screen and (max-width: 576px) {
  .imgSectionContainer .featureImg .imgContainer {
    height: 400px;
    width: 400px;
  }
}
@media only screen and (max-width: 320px) {
  .imgSectionContainer .featureImg .imgContainer {
    height: 300px;
    width: 300px;
  }
}/*# sourceMappingURL=ImageSection.css.map */