.DailyProduction .DailyProductionContent {
  background: url("../../assets/whiteBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 140px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.DailyProduction .DailyProductionContent .Info {
  text-align: center;
}
.DailyProduction .DailyProductionContent .Info h2 {
  line-height: 50px;
  font-weight: 700;
}
.DailyProduction .DailyProductionImage {
  height: 100%;
  background: url("../../assets/supportbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 15px;
}
.DailyProduction .DailyProductionImage h3 {
  color: var(--primary-color);
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: 50px;
  color: #fff;
  line-height: 38px;
}/*# sourceMappingURL=Support.css.map */