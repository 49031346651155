.ContactInfo{
    background: #F7F7F7;
    padding: 100px 0;
    .info{
        padding: 90px 90px;
        background: #000;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h2{
            color: #fff;
            padding-bottom: 8px;
        }

        .infoItem{
            margin-top: 100px;
            h6{
                color: var(--primary-white-light);
                line-height: 26px;
            }
        }
    }
    .contactForm{
        padding: 50px 40px; 
    }
}

@media only screen and (max-width: 768px) { 
   
    .ContactInfo{
        background: #F7F7F7;
        padding: 100px 0;
        .info{
            padding: 90px 40px;
        }
        .contactForm{
            padding: 50px 20px; 
        }
    }
    
}
@media only screen and (max-width: 575px) { 
   
    .ContactInfo{
        background: #F7F7F7;
        padding: 0 0;
        .info{
            padding: 90px 20px;
        }
        .contactForm{
            padding: 50px 10px; 
        }
    }
    
}

