.footerContainer .map {
  width: 100%;
  height: 70vh;
  position: relative;
}
.footerContainer .map .mapImg {
  width: 100%;
  height: 100%;
  background: url("../../assets/map.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.footerContainer .footerLogoContainer {
  background: var(--primary-black);
  padding: 15px 0 15px 0;
}
.footerContainer .footerLogoContainer .footerLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.footerContainer .footerLogoContainer .footerLogo h6 {
  color: var(--primary-white-light);
  margin-top: 5px;
}

@media only screen and (max-width: 1200px) {
  .footerContainer .map {
    height: 40vh;
  }
}
@media only screen and (max-width: 992px) {
  .footerContainer .map {
    height: 35vh;
  }
}
@media only screen and (max-width: 768px) {
  .footerContainer .map {
    height: 30vh;
  }
}
@media only screen and (max-width: 540px) {
  .footerContainer .map {
    height: 25vh;
  }
}
@media only screen and (max-width: 480px) {
  .footerContainer .map {
    height: 20vh;
  }
}
@media only screen and (max-width: 320px) {
  .footerContainer .map {
    height: 12vh;
  }
}/*# sourceMappingURL=Footer.css.map */