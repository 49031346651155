.Navbar{
    width: 100%;
    transition: all 1s ease-in;
    position: sticky;
    top: 0;
    z-index: 111;
    padding: 10px 0;
    background: #fff;
    h6{
        margin: 0;
    }

    nav{
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        .NavItem{
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            .NavLink{
                display: flex;
                justify-content: space-around;
                align-items: center;
                position: relative;
                a{
                    padding-top: 6px;
                    margin: 0 30px;
                    cursor: pointer;
                }
                .navLinks{
                    text-decoration: none;
                    margin-right: 5px;
                    padding: 3px 10px;
                    color: #0B2B43;
                    letter-spacing: .5px;
                    h6{
                        font-weight: 700;
                    }
                    img{
                        position: absolute;
                        margin-top: 7px;
                    }
                }
                
            }
        }

        .Contact_Now{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px 35px;
            border: 1px solid #000;
            border-radius: 5px;
            img{
                padding-right: 15px;
            }
            .Number{
                h6{
                    padding:2px 0;
                    color: #000;
                }
            }
        }
    }
}






// ============= Responsive Start ===========

@media (max-width: 1240px){
    .Navbar{
        nav{
            .NavItem{
                button{
                    margin-left: 10px;
                    padding: 4px 20px;
                }
                .padding{
                    margin-left: 40px;
                    margin-top: 5px;
                    position: absolute;
                    width: 20px;
                }
                .NavLink{
                    a{
                        margin: 0 6px;
                    }
                    .navLinks{
                        h6{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}


@media (max-width: 992px){
    .Navbar{
        display: none;
        transition: all 1s ease-in;
    }
}