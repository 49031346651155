.aboutBgContainer {
  width: 100%;
  height: 100%;
  position: relative;
}
.aboutBgContainer .aboutBg {
  width: 100%;
  height: 100%;
  background: url("../../assets/aboutBackground.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 120px 0px 120px 0px;
}
.aboutBgContainer .aboutBg .about {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.aboutBgContainer .aboutBg .about h2 {
  font-weight: 600;
  color: var(--primary-white);
  padding-bottom: 5px;
}
.aboutBgContainer .aboutBg .about p {
  color: var(--primary-white-light);
  margin-top: 35px;
  letter-spacing: 1px;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
}/*# sourceMappingURL=About.css.map */