
.contactForm{
    .leaveMessage{
        display: flex;
        align-items: center;
        justify-content: center;
        h3{
            color: var(--primary-black);
            font-weight: 600;
        }

    }
   
    .inputContainer{
        display: flex;
        flex-direction: column;
        height: 42px;
        width: 100%;
        border: 1px solid black;
        border-radius: 5px;
        // background: transparent;
        padding-bottom: 12px;
        margin-top: 45px;
        .labelContainer{
            margin-top: -12px;
            padding-left: 25px;
            label{
                background: #fff;
                color: var(--primary-black);
                font-weight: 600;
            }
    
        }
      
        input{
            border-style: none;
            background-color: transparent;
            padding:0 12px 0 12px;
            // margin-bottom: 15px;
            margin-top: -2px;
        }
        input:focus{
            outline: none;
        }
    }

    .textAreaContainer{
        display: flex;
        flex-direction: column;
        width: 100%;
        border: 1px solid black;
        border-radius: 5px;
        background: #fff;
        margin-top: 45px;
        .labelContainer{
            margin-top: -12px;
            padding-left: 25px;
            label{
                background: #fff;
                color: var(--primary-black);
                font-weight: 600;
            }
    
        }
      
        textarea{
            border-style: none;
            background-color: transparent;
            padding:0 12px 0 12px;
            margin-top: -2px;
        }
        textarea:focus{
            outline: none;
        }
       
    }
    .submit{
        display: flex;
        align-items: center;
        justify-content: center;
        button{
            border-style: none;
            background: var(--primary-black);
            padding: 12px 40px;
            border-radius: 25px;
            color: #fff;
            margin-top: 45px;
            font-size: 16px;
        }

    }
  

}
