@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
}

:root{
    --primary-black:#010101;
    --primary-black-light:#333333;
    --text-black-light:#354856;
    --primary-white:#fff;
    --primary-white-light:#DEDDDD;
    --body-bg-color: #f7f7f7;
}

body{
    background: var(--body-bg-color); 
    font-family: 'Nunito', sans-serif;
}


@mixin main($marginRight,$marginLeft) {
    margin: 0px $marginRight 0px $marginLeft;
}

::-webkit-scrollbar {
    width: 7px;
    margin: 0px 2px;
    
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: lightgray;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: gray; 
    border-radius: 50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #655757; 
  }


  .layout{
    @include main(60px ,60px);
    position: relative;
 }


 
  @media only screen and (max-width: 1200px) { 
  }

  @media only screen and (max-width: 992px) { 

  }
  @media only screen and (max-width: 768px) { 
   
  }
  @media only screen and (max-width: 576px) { 
    
  }
  @media only screen and (max-width: 320px) { 
    
  }