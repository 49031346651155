.sideNav {
  display: none;
}

.sideNav_Content_close {
  display: none;
}

.sideNav_Content {
  display: none;
}

.CloseBtn {
  background: none;
  border: none;
}

@media (max-width: 992px) {
  .sideNav {
    background: white;
    width: 100%;
    display: block;
    transition: all 1s ease-in;
    position: sticky;
    top: 0;
    z-index: 111;
  }
  .sideNav h6 {
    margin: 0;
  }
  .sideNav .Side_Nav {
    width: 100%;
  }
  .sideNav .Side_Nav .MenuBar {
    border: none;
    background: none;
  }
  .sideNav_Content {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 11111;
    background: rgba(0, 0, 0, 0.4);
    display: block;
  }
  .sideNav_Content .sideNav_bg {
    width: 350px;
    height: 100vh;
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 0.7s ease-in-out;
    z-index: 11111;
    padding: 20px 25px;
  }
  .sideNav_Content .sideNav_bg .CloseBtn {
    background: none;
    border: none;
  }
  .sideNav_Content_close {
    display: block;
  }
  .sideNav_Content_close .sideNav_bg_close {
    width: 350px;
    height: 100vh;
    background: #F7F7FC;
    position: fixed;
    top: 0;
    left: -400px;
    transition: all 0.7s ease-in-out;
    z-index: 11111;
  }
  .sideNav_Content_close .sideNav_bg_close dis .CloseBtn {
    background: none;
    border: none;
  }
}/*# sourceMappingURL=SideNav.css.map */