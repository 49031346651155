.proFeaturesContainer {
  background: var(--primary-black);
  padding: 80px 0 80px 0;
}
.proFeaturesContainer .titleSection h1 {
  color: var(--primary-white);
  font-weight: 500;
}
.proFeaturesContainer .titleSection p {
  color: var(--primary-white-light);
  font-weight: 400;
  font-size: 16;
  line-height: 26px;
  padding-top: 10px;
}
.proFeaturesContainer h5 {
  color: var(--primary-white);
  font-weight: 500;
  margin-top: 25px;
}
.proFeaturesContainer p {
  color: var(--primary-white-light);
  font-weight: 400;
  font-size: 16;
  line-height: 26px;
  padding-top: 8px;
}/*# sourceMappingURL=UpoPro.css.map */