.DailyProduction{
    .DailyProductionContent{
        background: url('../../assets/whiteBg.png');
        background-repeat: no-repeat;
        background-size: cover;
        padding: 140px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .Info{
            text-align: center;
            h2{
                line-height: 50px;
                font-weight: 700;
            }
        }
        
    }
    .DailyProductionImage{
        height: 100%;
        background: url('../../assets/supportbg.png');
        background-repeat: no-repeat;
        background-size: cover;
        padding: 120px 15px;

        h3{
            color: var(--primary-color);
            font-weight: 700;
            letter-spacing: 1px;
            margin-top: 50px;
            color: #fff;
            line-height: 38px;
        }
    }
   
}
