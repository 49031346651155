.liteFeaturesContainer {
  background: var(--primary-white);
  padding: 80px 0 80px 0;
}
.liteFeaturesContainer .liteFeaturesBg {
  background: var(--primary-black);
  border-radius: 10px;
  padding: 30px;
  height: 100%;
}
.liteFeaturesContainer .liteFeaturesBg h5 {
  color: var(--primary-black);
  font-weight: 600;
  margin-top: 25px;
  color: #fff;
}
.liteFeaturesContainer .liteFeaturesBg p {
  font-weight: 400;
  font-size: 16;
  line-height: 26px;
  padding-top: 8px;
  color: var(--primary-white-light);
}
.liteFeaturesContainer .titleSection h1 {
  color: var(--primary-black);
  font-weight: 500;
}
.liteFeaturesContainer .titleSection p {
  color: var(--primary-text-light-color);
  font-weight: 400;
  font-size: 16;
  line-height: 26px;
  padding-top: 10px;
}/*# sourceMappingURL=UpoLite.css.map */