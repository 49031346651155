.contactForm .leaveMessage {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactForm .leaveMessage h3 {
  color: var(--primary-black);
  font-weight: 600;
}
.contactForm .inputContainer {
  display: flex;
  flex-direction: column;
  height: 42px;
  width: 100%;
  border: 1px solid black;
  border-radius: 5px;
  padding-bottom: 12px;
  margin-top: 45px;
}
.contactForm .inputContainer .labelContainer {
  margin-top: -12px;
  padding-left: 25px;
}
.contactForm .inputContainer .labelContainer label {
  background: #fff;
  color: var(--primary-black);
  font-weight: 600;
}
.contactForm .inputContainer input {
  border-style: none;
  background-color: transparent;
  padding: 0 12px 0 12px;
  margin-top: -2px;
}
.contactForm .inputContainer input:focus {
  outline: none;
}
.contactForm .textAreaContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid black;
  border-radius: 5px;
  background: #fff;
  margin-top: 45px;
}
.contactForm .textAreaContainer .labelContainer {
  margin-top: -12px;
  padding-left: 25px;
}
.contactForm .textAreaContainer .labelContainer label {
  background: #fff;
  color: var(--primary-black);
  font-weight: 600;
}
.contactForm .textAreaContainer textarea {
  border-style: none;
  background-color: transparent;
  padding: 0 12px 0 12px;
  margin-top: -2px;
}
.contactForm .textAreaContainer textarea:focus {
  outline: none;
}
.contactForm .submit {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactForm .submit button {
  border-style: none;
  background: var(--primary-black);
  padding: 12px 40px;
  border-radius: 25px;
  color: #fff;
  margin-top: 45px;
  font-size: 16px;
}/*# sourceMappingURL=Input.css.map */