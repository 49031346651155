.aboutBgContainer{
    width: 100%;
    height: 100%;
    position: relative;
    .aboutBg{
        width: 100%;
        height: 100%;
        background: url('../../assets/aboutBackground.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        padding: 120px 0px 120px 0px;
        .about{
            display: flex;
            align-items: center;
            flex-direction: column;
            h2{
                font-weight: 600;
                color: var(--primary-white);
                padding-bottom: 5px;
            } 

            p{
                color: var(--primary-white-light);
                margin-top: 35px;
                letter-spacing: 1px;
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 0;
            }
          }
    }
}



// Responsive 
// @media(max-width: 992px){
//     .aboutBgContainer{
//         height: 70vh;
//     }
// }
// @media(max-width: 768px){
//     .aboutBgContainer{
//         height: 80vh;
//     }
// }
// @media(max-width: 576px){
//     .aboutBgContainer{
//         height: 100vh;
//         .BannerImage{
//             .BannerContent{
//                 h1{
//                     line-height: 45px;
//                 }
//             } 
//         }
//     }
// }
// @media(max-width: 480px){
//     .aboutBgContainer{
//         height: 90vh;
//         .BannerImage{
//             .BannerContent{
//                 h1{
//                     line-height: 45px;
//                 }
//             } 
//         }
//     }
// }