.useCaseContainer {
  background: var(--body-bg-color);
  padding: 180px 0 100px 0;
}
.useCaseContainer .usages {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.useCaseContainer .usages h2 {
  color: var(--primary-black);
  font-weight: 600;
  padding-bottom: 5px;
}
.useCaseContainer .usages p {
  color: var(--primary-black-light);
  font-size: 16;
  line-height: 26px;
  margin-top: 25px;
}
.useCaseContainer .useCard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--primary-white);
  padding: 45px 0 40px 0;
  border: 1px solid #DEDDDD;
  border-radius: 10px;
}
.useCaseContainer .useCard h6 {
  color: #354856;
  font-weight: 700;
  margin-top: 25px;
}/*# sourceMappingURL=Use.css.map */