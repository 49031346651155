.SideNavContent {
  margin-top: 50px;
}
.SideNavContent nav .NavItem button {
  background: #009054;
  border: none;
  padding: 5px 30px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--primary-white-light);
}
.SideNavContent nav .NavItem .NavLink .navLinks {
  text-decoration: none;
  position: relative;
  display: flex;
  align-items: center;
}
.SideNavContent nav .NavItem .NavLink .navLinks h6 {
  color: var(--primary-white-light);
  margin: 0;
  padding: 1px 50px;
  margin-top: 30px;
  font-weight: 500;
}
.SideNavContent nav .NavItem .NavLink .navLinks .ActiveColor {
  position: absolute;
  left: 0;
  width: 4px;
  margin-top: 30px;
  height: 25px;
  background: linear-gradient(180deg, #33ECEC 0%, #03AAF0 100%);
  transition: all 0.7s ease-in-out;
}
.SideNavContent nav .NavItem .NavLink .navLinks .Active {
  color: #fff;
}
.SideNavContent nav .CallNow {
  width: 100%;
  height: 98vh;
  align-items: end;
  display: flex;
}
.SideNavContent nav .CallNow .Contact_Now {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #33ECEC 0%, #03AAF0 100%);
  width: 230px;
  border-radius: 5px;
  padding: 15px 0;
}
.SideNavContent nav .CallNow .Contact_Now .Number h6 {
  color: #0B2B43;
  font-weight: 800;
  color: #000;
}
.SideNavContent nav .CallNow .Contact_Now .Number h6:last-child {
  margin: 0;
}

@media (max-width: 576px) {
  .Content_bg {
    height: 72vh;
  }
}/*# sourceMappingURL=SideNavContent.css.map */