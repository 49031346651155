.ContactInfo {
  background: #F7F7F7;
  padding: 100px 0;
}
.ContactInfo .info {
  padding: 90px 90px;
  background: #000;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ContactInfo .info h2 {
  color: #fff;
  padding-bottom: 8px;
}
.ContactInfo .info .infoItem {
  margin-top: 100px;
}
.ContactInfo .info .infoItem h6 {
  color: var(--primary-white-light);
  line-height: 26px;
}
.ContactInfo .contactForm {
  padding: 50px 40px;
}

@media only screen and (max-width: 768px) {
  .ContactInfo {
    background: #F7F7F7;
    padding: 100px 0;
  }
  .ContactInfo .info {
    padding: 90px 40px;
  }
  .ContactInfo .contactForm {
    padding: 50px 20px;
  }
}
@media only screen and (max-width: 575px) {
  .ContactInfo {
    background: #F7F7F7;
    padding: 0 0;
  }
  .ContactInfo .info {
    padding: 90px 20px;
  }
  .ContactInfo .contactForm {
    padding: 50px 10px;
  }
}/*# sourceMappingURL=ContactInfo.css.map */