.Banner{
    background: #fff;
    padding: 40px 0;
    .Content{
        p{
            margin-bottom: 60px;
            margin-top: 30px;
        }
        a{
            text-decoration: none;
            border: 2px solid #000;
            color: #000;
            font-weight: 700;
            padding: 10px 30px;
            border-radius: 5px;
            transition: all 0.5s ease-in-out;
        }
        a:hover{
            background: #000;
            color: #fff;
            transition: all 0.5s ease-in-out;
        }
    }
}
@media only screen and (max-width: 992px) { 
    .Banner{
        padding: 40px 0 60px 0; 
    }

}